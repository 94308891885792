/**------------ Variable Roots for CSS ------------ **/
/*!------------ Header Formatting ------------ !*/
:root {
	--header-height: 3rem;
	--font-semi: 600;
}

/*!------------ Stylization Colors ------------ !*/
:root {
	/*!----- Text Colors -----!*/
	--first-text: #86c232; /** ----- Lime Green ----- **/
	--second-text: #61892f; /** ----- Flat Pea Soup Green ----- **/
	/*!----- Background Colors -----!*/
	--fist-bkg: #222629; /** ----- Dark Charcole ----- **/
	--second-bkg: #474b4f; /** ----- Darker mid Grey ----- **/
	--third-bkg: #6b6e70; /** ----- Light Grey ----- **/
	/*!----- Additional Colors -----!*/
	--addititional-color-first: #edeae5; /** ----- Light Smokey Grey ----- **/
	--addititional-color-first: #ffffff; /** ----- White ----- **/
	--addititional-color-first: #eeebe6; /** ----- Linen ----- **/
}

/*!----- Text Formatting and Size -----!*/
:root {
	--body-font: 'Muli', sans-serif;
	--big-font-size: 2rem;
	--h2-font-size: 1.25rem;
	--normal-font-size: 0.938rem;
}

/*!----- Text Formatting and Size Media Query @ Break Point -----!*/
@media screen and (min-width: 768px) {
	:root {
		--big-font-size: 3.5rem;
		--h2-font-size: 2rem;
		--normal-font-size: 1rem;
	}
}

/*!----- Margin Formatting -----!*/
:root {
	--mb-1: 0.5rem;
	--mb-2: 1rem;
	--mb-3: 1.5rem;
	--mb-4: 2rem;
	--mb-5: 2.5rem;
	--mb-6: 3rem;
}

/*!----- Z Index Formatting -----!*/
:root {
	--z-back: -10;
	--z-normal: 1;
	--z-tooltip: 10;
	--z-fixed: 50;
}

.BD-Grid-Contact {
	max-width: 1024px;
	display: grid;
	grid-template-columns: 100%;
	grid-column-gap: 2rem;
	width: calc(100% - 2rem);
	margin-left: var(--mb-2);
	margin-right: var(--mb-2);
}

.Section-Title {
	position: relative;
	font-size: var(--h2-font-size);
	color: var(--first-color);
	margin-top: var(--mb-2);
	margin-bottom: var(--mb-4);
	text-align: center;
}
.Section-Title::after {
	position: absolute;
	content: '';
	width: 64px;
	height: 0.18rem;
	left: 0;
	right: 0;
	margin: auto;
	top: 2rem;
	background-color: var(--first-color);
}
.Section {
	padding-top: 3rem;
	padding-bottom: 2rem;
}

.Contact {
	height: 100vh;
	row-gap: 1rem;
}

.Contact-Input {
	width: 100%;
	font-size: var(--normal-font-size);
	font-weight: var(--font-semi);
	padding: 1rem;
	border-radius: 0.5rem;
	border: 1.5px solid var(--second-color);
	outline: none;
	margin-bottom: var(--mb-4);
}
.Contact-Button {
	display: block;
	border: none;
	outline: none;
	font-size: var(--normal-font-size);
	cursor: pointer;
	margin-left: auto;
}

@media screen and (max-width: 768px) {
	body {
		margin: 0;
	}
	.Section {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
	.Section-Title {
		margin-bottom: var(--mb-4);
	}
	.Section-Title::after {
		width: 80px;
		top: 3rem;
	}
	.Contact {
		height: 100%;
		row-gap: 1rem;
	}
	.Contact-Form {
		width: 360px;
	}
	.Contact-Container {
		justify-items: center;
	}
	.Contact-Input {
		width: 100%;
		font-size: var(--normal-font-size);
		font-weight: var(--font-semi);
		padding: 1rem;
		border-radius: 0.5rem;
		border: 1.5px solid var(--second-color);
		outline: none;
		margin-bottom: var(--mb-4);
	}
}

@media screen and (min-width: 1024px) {
	.bd-grid {
		margin-left: auto;
		margin-right: auto;
	}
	.Contact {
		height: 100vh; /*! --- Set to 100% to accommodate for div size !*/
		row-gap: 1rem;
	}
}
