footer {
	background: #303036;
	color: #d3d3d3;
	position: relative;
}

footer .Footer-Bottom {
	background: #343a40;
	color: #686868;
	height: 40px;
	width: 100%;
	text-align: center;
	position: absolute;
	bottom: 0px;
	left: 0px;
	padding: 7px 0px;
}
