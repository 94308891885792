/**------------ Variable Roots for CSS ------------ **/
/*!------------ Header Formatting ------------ !*/
:root {
	--header-height: 3rem;
	--font-semi: 600;
}

/*!------------ Stylization Colors ------------ !*/
:root {
	/*!----- Text Colors -----!*/
	--first-text: #86c232; /** ----- Lime Green ----- **/
	--second-text: #61892f; /** ----- Flat Pea Soup Green ----- **/
	/*!----- Background Colors -----!*/
	--fist-bkg: #222629; /** ----- Dark Charcole ----- **/
	--second-bkg: #474b4f; /** ----- Darker mid Grey ----- **/
	--third-bkg: #6b6e70; /** ----- Light Grey ----- **/
	/*!----- Additional Colors -----!*/
	--addititional-color-first: #edeae5; /** ----- Light Smokey Grey ----- **/
	--addititional-color-second: #ffffff; /** ----- White ----- **/
	--addititional-color-third: #eeebe6; /** ----- Linen ----- **/
}

/*!----- Text Formatting and Size -----!*/
:root {
	--body-font: 'Muli', sans-serif;
	--big-font-size: 2rem;
	--h2-font-size: 1.25rem;
	--normal-font-size: 0.938rem;
}

/*!----- Text Formatting and Size Media Query @ Break Point -----!*/
@media screen and (min-width: 768px) {
	:root {
		--big-font-size: 3.5rem;
		--h2-font-size: 2rem;
		--normal-font-size: 1rem;
	}
}

/*!----- Margin Formatting -----!*/
:root {
	--mb-1: 0.5rem;
	--mb-2: 1rem;
	--mb-3: 1.5rem;
	--mb-4: 2rem;
	--mb-5: 2.5rem;
	--mb-6: 3rem;
}

/*!----- Z Index Formatting -----!*/
:root {
	--z-back: -10;
	--z-normal: 1;
	--z-tooltip: 10;
	--z-fixed: 50;
}

.BD-Grid {
	max-width: 1024px;
	display: grid;
	grid-template-columns: 100%;
	grid-column-gap: 2rem;
	width: calc(100% - 2rem);
	margin-left: var(--mb-2);
	margin-right: var(--mb-2);
	justify-items: center;
	justify-content: space-evenly;
}

.Section-Title {
	position: relative;
	font-size: var(--h2-font-size);
	color: var(--first-color);
	margin-top: var(--mb-2);
	margin-bottom: var(--mb-4);
	text-align: center;
}
.Section-Title::after {
	position: absolute;
	content: '';
	width: 64px;
	height: 0.18rem;
	left: 0;
	right: 0;
	margin: auto;
	top: 2rem;
	background-color: var(--first-color);
}
.Section {
	padding-top: 3rem;
	padding-bottom: 2rem;
}

.Portfolio {
	height: 100%; /*! --- Set to 100% to accommodate for div size !*/
	row-gap: 1rem;
}

.Portfolio-Container {
	row-gap: 2rem;
}
.Work-IMG {
	box-shadow: 5px 10px 8px #212426;
	overflow: hidden;
	position: relative;
	display: flex;
	justify-content: center;
	background-color: var(--second-bkg);
}

.Work-IMG:hover {
	background-color: var(--third-bkg);
}

.Work-IMG {
	transition: 1s;
	cursor: pointer;
	height: 330px;
	width: 330px;
}

.Work-IMG-CSS-Style {
	position: absolute;
	top: 0;
	width: 330px;
	border: 1px solid var(--first-color);
	z-index: 5;
	transition: 1s;
}

.CropBlockCSS {
	position: absolute;
	width: 100%;
	height: 120px;
	background-color: var(--second-bkg);
	bottom: 0;
	z-index: 6;
}

.Tnail-CSS {
	position: absolute;
	bottom: 10px;
	font-size: 24px;
	font-weight: 500;
	z-index: 7;
	text-align: center;
	background: var(--first-text);
	padding: 10px 30px;
	border-radius: 15px;
	box-shadow: 5px 2px 8px #212426;
	z-index: 7;
}

.Tnail-CSS:hover {
	color: var(--first-text);
	background: var(--addititional-color-second);
	border: 1px solid var(--first-text);
}

.Work-IMG-Bottom-Button {
	position: absolute;
	bottom: 5px;
	font-size: 14px;
	font-weight: 500;
}

.Modal-CSS {
	z-index: 12;
	height: 85%;
	width: 90%;
	opacity: 1;
	background: #474b4f;
	border: 1px solid #6b6e70;
	position: fixed;
	top: 60px;
	left: 50%;
	transform: translate(-50%, 0);
	box-shadow: 5px 10px 8px #212426;
}

.Modal-BTN {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	float: right;
	margin: 10px;
}

.Modal-BTN-Icon {
	color: red;
	background: transparent;
	font-size: 20px;
}

.Modal-Title {
	font-size: 13px;
	padding-bottom: 5px;
	margin-left: 35px;
	margin-right: 20px;
	margin-top: 10px;
	margin-bottom: 20px;
	text-align: center;
	border-bottom: 1px solid #6b6e70;
}

.Modal-Description {
	font-size: 10px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 2px;
	text-align: center;
}

.Modal-GIF {
	height: 100px;
	position: relative;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	box-shadow: 5px 5px 8px #212426;
}

.Modal-Stack-Header {
	text-align: center;
	font-size: 16px;
	margin-left: 35px;
	margin-right: 35px;
	margin-bottom: 5px;
}

.Modal-Stack-List {
	font-size: 10px;
	text-align: center;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 5px;
}

.Modal-App-Links {
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	bottom: 4%;
}

.Modal-Github-Link {
	text-decoration: none;
}

.Modal-Deployed-Link {
	text-decoration: none;
}

.Modal-Link-Icon {
	margin-right: 5px;
	margin-left: 18px;
	font-size: 35px;
}

.Modal-Link-Icon.PrivateRepo {
	color: red;
	pointer-events: none;
	cursor: default;
	text-decoration: none;
	opacity: 0.5;
}

.Modal-Link-Icon.PublicRepo {
	color: white;
}

.PublicRepo:hover {
	color: #86c232;
}

.Modal-Private-Note {
	font-size: 10px;
	text-align: center;
	color: red;
	font-weight: 500;
	position: relative;
	margin-top: 10px;
}

@media screen and (min-width: 420px) {
	.Modal-CSS {
		z-index: 10;
		height: 85%;
		width: 80%;
		opacity: 1;
		background: #474b4f;
		border: 1px solid #6b6e70;
		position: fixed;
		top: 50px;
		left: 50%;
		transform: translate(-50%, 0);
		box-shadow: 5px 10px 8px #212426;
	}

	.Modal-BTN {
		background: none;
		color: inherit;
		border: none;
		padding: 0;
		font: inherit;
		cursor: pointer;
		outline: inherit;
		float: right;
		margin: 10px;
	}

	.Modal-BTN-Icon {
		color: red;
		background: transparent;
		font-size: 20px;
	}

	.Modal-Title {
		font-size: 20px;
		padding-bottom: 5px;
		margin-left: 35px;
		margin-right: 20px;
		margin-top: 10px;
		margin-bottom: 20px;
		text-align: center;
		border-bottom: 1px solid #6b6e70;
	}

	.Modal-Description {
		font-size: 12px;
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 2px;
		text-align: center;
	}

	.Modal-GIF {
		height: 140px;
		position: relative;
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		box-shadow: 5px 5px 8px #212426;
	}

	.Modal-Stack-Header {
		text-align: center;
		font-size: 16px;
		margin-left: 35px;
		margin-right: 35px;
		margin-bottom: 5px;
	}

	.Modal-Stack-List {
		font-size: 12px;
		text-align: center;
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 5px;
	}

	.Modal-App-Links {
		position: absolute;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		bottom: 4%;
	}

	.Modal-Github-Link {
		text-decoration: none;
	}

	.Modal-Deployed-Link {
		text-decoration: none;
	}

	.Modal-Link-Icon {
		margin-right: 20px;
		margin-left: 20px;
		font-size: 50px;
		color: white;
	}

	.Modal-Private-Note {
		font-size: 14px;
		text-align: center;
		color: red;
		font-weight: 500;
		position: relative;
		margin-top: 10px;
	}
}

@media screen and (min-width: 768px) {
	body {
		margin: 0;
	}
	.Section {
		padding-top: 4rem;
		padding-bottom: 3rem;
	}
	.Section-Title {
		margin-bottom: var(--mb-6);
	}
	.Section-Title::after {
		width: 80px;
		top: 3rem;
	}

	.Portfolio-Container {
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);
		column-gap: 1.5rem;
	}

	.BD-Grid {
		justify-content: space-evenly;
	}

	.Portfolio {
		height: 55vh; /*! --- Set to 100% to accommodate for div size !*/
		row-gap: 1rem;
	}

	.Modal-CSS {
		z-index: 10;
		height: 75%;
		width: 65%;
		opacity: 1;
		background: #474b4f;
		border: 1px solid #6b6e70;
		position: fixed;
		top: 60px;
		left: 50%;
		transform: translate(-50%, 0);
		box-shadow: 5px 10px 8px #212426;
	}

	.Modal-BTN {
		background: none;
		color: inherit;
		border: none;
		padding: 0;
		font: inherit;
		cursor: pointer;
		outline: inherit;
		float: right;
		margin: 10px;
	}

	.Modal-BTN-Icon {
		color: red;
		background: transparent;
		font-size: 20px;
	}

	.Modal-Title {
		font-size: 30px;
		padding-bottom: 5px;
		margin-left: 35px;
		margin-right: 20px;
		margin-top: 20px;
		margin-bottom: 20px;
		text-align: center;
		border-bottom: 1px solid #6b6e70;
	}

	.Modal-Description {
		font-size: 14px;
		margin-left: 35px;
		margin-right: 35px;
		margin-bottom: 5px;
		text-align: center;
	}

	.Modal-GIF {
		height: 200px;
		position: relative;
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		box-shadow: 5px 5px 8px #212426;
	}

	.Modal-Stack-Header {
		text-align: center;
		margin-left: 35px;
		margin-right: 35px;
		margin-bottom: 5px;
	}

	.Modal-Stack-List {
		font-size: 14px;
		text-align: center;
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 5px;
	}

	.Modal-App-Links {
		position: absolute;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		bottom: 4%;
	}

	.Modal-Github-Link {
		text-decoration: none;
	}

	.Modal-Deployed-Link {
		text-decoration: none;
	}

	.Modal-Link-Icon {
		margin-right: 20px;
		margin-left: 20px;
		font-size: 50px;
		color: white;
	}

	.Modal-Private-Note {
		font-size: 14px;
		text-align: center;
		color: red;
		font-weight: 500;
		position: relative;
		margin-top: 10px;
	}
}

@media screen and (min-width: 1024px) {
	.Modal-CSS {
		z-index: 10;
		height: 83%;
		width: 700px;
		opacity: 1;
		background: #474b4f;
		border: 1px solid #6b6e70;
		position: fixed;
		top: 60px;
		left: 50%;
		transform: translate(-50%, 0);
		box-shadow: 5px 10px 8px #212426;
	}

	.Modal-Title {
		font-size: 35px;
		padding-bottom: 10px;
		margin-left: 35px;
		margin-right: 20px;
		margin-top: 20px;
		margin-bottom: 20px;
		text-align: center;
		border-bottom: 1px solid #6b6e70;
	}

	.Modal-Description {
		font-size: 16px;
		margin-left: 60px;
		margin-right: 60px;
		margin-bottom: 5px;
		text-align: center;
	}

	.Modal-GIF {
		height: 250px;
		position: relative;
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 25px;
		box-shadow: 5px 5px 8px #212426;
	}

	.Modal-Stack-Header {
		text-align: center;
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 10px;
	}

	.Modal-Stack-List {
		font-size: 16px;
		text-align: center;
		margin-left: 60px;
		margin-right: 60px;
		margin-bottom: 5px;
	}

	.Modal-App-Links {
		position: absolute;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		bottom: 4%;
	}

	.Modal-Github-Link {
		text-decoration: none;
	}

	.Modal-Deployed-Link {
		text-decoration: none;
	}

	.Modal-Link-Icon {
		margin-right: 20px;
		margin-left: 20px;
		font-size: 50px;
		color: white;
	}
}
