/**------------ Variable Roots for CSS ------------ **/
/*!------------ Header Formatting ------------ !*/
:root {
	--header-height: 3rem;
	--font-semi: 600;
}

/*!------------ Stylization Colors ------------ !*/
:root {
	/*!----- Text Colors -----!*/
	--first-text: #86c232; /** ----- Lime Green ----- **/
	--second-text: #61892f; /** ----- Flat Pea Soup Green ----- **/
	/*!----- Background Colors -----!*/
	--fist-bkg: #222629; /** ----- Dark Charcole ----- **/
	--second-bkg: #474b4f; /** ----- Darker mid Grey ----- **/
	--third-bkg: #6b6e70; /** ----- Light Grey ----- **/
	/*!----- Additional Colors -----!*/
	--addititional-color-first: #edeae5; /** ----- Light Smokey Grey ----- **/
	--addititional-color-first: #ffffff; /** ----- White ----- **/
	--addititional-color-first: #eeebe6; /** ----- Linen ----- **/
}

/*!----- Text Formatting and Size -----!*/
:root {
	--body-font: 'Muli', sans-serif;
	--big-font-size: 2rem;
	--h2-font-size: 1.25rem;
	--normal-font-size: 0.938rem;
}

/*!----- Text Formatting and Size Media Query @ Break Point -----!*/
@media screen and (min-width: 768px) {
	:root {
		--big-font-size: 3.5rem;
		--h2-font-size: 2rem;
		--normal-font-size: 1rem;
	}
}

/*!----- Margin Formatting -----!*/
:root {
	--mb-1: 0.5rem;
	--mb-2: 1rem;
	--mb-3: 1.5rem;
	--mb-4: 2rem;
	--mb-5: 2.5rem;
	--mb-6: 3rem;
}

/*!----- Z Index Formatting -----!*/
:root {
	--z-back: -10;
	--z-normal: 1;
	--z-tooltip: 10;
	--z-fixed: 50;
}

/** Home Section Classes **/

/*! Commenting Out This Section Which Causes Displaying Issues
.Home-Container {
	position: absolute;
	width: 100%;
	height: 90%;
	background-repeat: no-repeat;
	background-size: cover;
}

!*/

.BD-Grid {
	max-width: 1024px;
	display: grid;
	grid-template-columns: 100%;
	grid-column-gap: 2rem;
	width: calc(100% - 2rem);
	margin-left: var(--mb-2);
	margin-right: var(--mb-2);
}

.Bkg-IMG-Container {
	z-index: var(--z-back);
}

.Bkg-IMG {
	position: relative;
	top: 100px;
	height: 100%;
	width: 100%;
}

.Home {
	height: calc(100vh - 3rem);
	row-gap: 1rem;
}

.Home-Left-Content {
	position: absolute;
	left: 10%; /*! Adjust for positioning of Left Paragraph Elemenets */
	bottom: 15%; /*! Adjust for positioning of Left Paragraph Elemenets */
	z-index: var(--z-normal);
}
.Home-Data {
	align-self: center;
}
.Home-Title {
	margin-bottom: var(--mb-5);
}
.Home-Title-Color {
	color: var(--first-color);
	font-size: var(--big-font-size);
	margin-bottom: var(--mb-5);
}

.Home-Paragraph {
	width: 80%;
	margin-bottom: 20px;
	font-size: 12px;
	font-weight: 600;
	line-height: 22px;
}

.Home-Social {
	display: flex;
	flex-direction: row;
}
.Home-Social-Icon {
	width: max-content;
	margin-bottom: var(--mb-2);
	font-size: 1rem;
	color: var(--first-text);
	margin-right: 2rem;
}
.Home-Social-Icon:hover {
	color: var(--third-color);
}
.Home-IMG {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 295px;
	z-index: var(--z-normal);
}

@media screen and (min-width: 768px) {
	body,
	html {
		height: 100%;
		width: 100%;
		margin: 0;
	}

	.Home {
		height: calc(100vh - 3rem);
	}
	.Home-Data {
		align-self: flex-end;
	}

	.Home-Left-Content {
		position: absolute;
		left: 10%; /*! Adjust for positioning of Left Paragraph Elemenets */
		bottom: 10%; /*! Adjust for positioning of Left Paragraph Elemenets */
		z-index: var(--z-normal);
	}

	.Home-Paragraph {
		width: 70%;
		margin-bottom: 20px;
		font-size: 16px;
		font-weight: 600;
		line-height: 22px;
	}

	.Home-Social {
		padding-top: 0;
		padding-bottom: 2.5rem;
		flex-direction: row;
		align-self: flex-end;
	}
	.Home-Social-Icon {
		margin-bottom: 0;
		margin-right: var(--mb-4);
	}
	.Home-IMG {
		width: 457px;
		bottom: 15%;
	}
}

@media screen and (min-width: 1024px) {
	.BD-Grid {
		margin-left: auto;
		margin-right: auto;
	}

	.Home-Paragraph {
		width: 70%;
		margin-bottom: 20px;
		font-size: 20px;
		font-weight: 500;
		line-height: 28px;
	}
	.Home-IMG {
		right: 5%; /*! -- Change for Position of Image !*/
		bottom: 25%; /*! Adjust for positioning of Left Paragraph Elemenets */
	}
	.Home {
		height: 40vh;
	}

	.Home-Left-Content {
		position: absolute;
		left: 10%; /*! Adjust for positioning of Left Paragraph Elemenets */
		bottom: 10%; /*! Adjust for positioning of Left Paragraph Elemenets */
		z-index: var(--z-normal);
	}
}
